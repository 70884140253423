import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/components/services/crud.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {

    public idCorsista : string="";
    public urlImage = environment.urlImageCorso;
    public elencoCorsiInHome : any=[];

    public idPartner: any='2';

    constructor(
        private crudService: CrudService
    ) { }


  ngOnInit(): void {
    let detailUser=JSON.parse(localStorage.getItem('user_id'));
    if(detailUser) {
        this.idCorsista=detailUser['id_corsista']
    }

    this.loadCorsiInHome();
  }

  loadCorsiInHome() {
    this.crudService.api_get_ElencoCorsiInHome(this.idCorsista, this.idPartner).subscribe(productData => {
        this.elencoCorsiInHome=productData;
    },(err)=>{
        //console.log(err);
        }, ()=>{
    }
);
}
}
