<div class="view-all-courses-area bg-fef8ef">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="view-all-courses-content">
                    <span class="sub-title">SENZA OBBLIGO DI FREQUENZA</span>
                    <h2>Lavori e vuoi diplomarti?</h2>
                    <p>
                        <u>Non è mai troppo tardi per conseguire il diploma!</u><br>
                        Per tutte le persone che sia per scelta personale o per necessità lavorative, hanno abbandonato la scuola da tempo ricominciare a studiare dopo tanti anni è difficile, soprattutto per chi non può conciliare il lavoro o gli impegni personali con lo studio.
                    </p>
                    <p>
                        Il servizio che offriamo consiste nel <b>sostenere l'esame di stato da privatista</b>, cioè senza obbligo di frequenza.
                    </p>
                    <a routerLink="/elenco-corsi" class="default-btn"><i class="flaticon-agenda"></i>Visualizza i Corsi<span></span></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="view-all-courses-image">
                    <img src="assets/img/man-with-laptop.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
