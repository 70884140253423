import { Component, OnInit, ViewChild } from '@angular/core';
import { CrudService } from '../../services/crud.service';
import { Router, ActivatedRoute} from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

//declare var $;

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss']
})
export class ReadComponent implements OnInit {
    public products: any=[];
    public productID: any='';
    public detailUser: string[];
    public idCorsista: string="";

    public findCorsista: string="";

    public elencoCorsi: any=[];
    public detailCorsista: any=[];
    public elencoSottoCategorie: any=[];
    public elencoPropostaCorsi: any=[];

    public changeFilter: string;
    public urlImage = environment.urlImageCorso;

    public dataTable: any;

    nPagAttuale: Number = 1;
    nOffset: Number = 6;

    public idPartner = '2';


  constructor(
      private crudService: CrudService,
      private router: Router,
      private actRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
      //mi recupero il dettaglio dell'user dopo il LOGIN
    this.detailUser=JSON.parse(localStorage.getItem('user_id'));

    if(this.detailUser) {
        this.idCorsista=this.detailUser['id_corsista']
        this.getDetailCorsista();
    }
    //mi recupero l'ID del CORSO per ricavare la scheda
    this.productID = this.actRoute.snapshot.params['id'];
    if(this.detailUser) {
        this.idCorsista=this.detailUser['id_corsista']
    }
    this.loadCorsi('');
    this.loadSottoCategorie();
    this.loadPropostaCorsi();

  }

  isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

  loadCorsi(pag){
    this.findCorsista="";
    var findSottoCategoria: string="";
    if(this.productID=="i-miei-corsi")
        this.findCorsista=this.idCorsista;
    else if(this.productID!=undefined)
        findSottoCategoria=this.productID;

    const queryFind = document.getElementById('queryFind') as HTMLButtonElement | null;
    if(queryFind.value) {
        pag=0;
        findSottoCategoria="";
    }

    this.crudService.api_get_ElencoCorsi(pag, this.findCorsista, findSottoCategoria, queryFind.value, this.idCorsista, this.idPartner).subscribe(
        productData => {
            //console.log(productData);
            if(productData) {
                if(this.productID=="i-miei-corsi" && (productData[0]['i_miei_corsi'].length<=0)) {
                }
                else
                    this.elencoCorsi=productData;
            }
        },(err)=>{
            //console.log(err);
            }, ()=>{
        }
    );
  }

  getDetailCorsista() {
    this.crudService.api_getDetailCorsista(this.detailUser['id_corsista']).subscribe(productData => {
        this.detailCorsista=productData;
        this.getDetailUser(productData['id_user']);

    },(err)=>{
        //console.log(err);
        }, ()=>{
    });
  }

  getDetailUser(idUser) {
    this.crudService.api_getDetailUser(idUser).subscribe(productData => {
        this.detailCorsista['username']=productData['username'];
        this.detailCorsista['password']=productData['password'];
    },(err)=>{
        //console.log(err);
    }, ()=>{
    });
  }


  loadSottoCategorie(){
    this.crudService.api_get_ElencoSottoCategorie(this.idPartner).subscribe(
        productData => {
            this.elencoSottoCategorie=productData;
        },(err)=>{
            //console.log(err);
            }, ()=>{
        }
    );
  }

  loadPropostaCorsi(){
    this.crudService.api_get_ElencoPropostaCorsi().subscribe(
        productData => {
            //console.log(productData);
            this.elencoPropostaCorsi=productData;
        },(err)=>{
            //console.log(err);
            }, ()=>{
        }
    );
  }

  queryFilter() {
      //console.log(this.changeFilter);
  }

  cambioPagina(pag) {
    this.loadCorsi(pag);
  }

  getNavigation(link, id){
    //console.log(link+id);
    if(id === ''){
        this.router.navigate([link]);
    } else {
        this.router.navigate([link + '/' + id]);
    }
  }

  cambiaPassword() {
    Swal.fire({
        icon: 'info',
        title: 'Sei sicuro?',
        text: 'vuoi cambiare la tua password d\'accesso?',
        showCancelButton: true,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Si, confermo!',
        cancelButtonText: 'Annulla',
        width: '400px',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
            Swal.fire({
                html: '<div class="main-banner-content mt-2">\
                        <h3 style="font-family:var(--fontFamily); color:var(--blackColor); margin-bottom: 0px; line-height: 1.3; font-size: 24px; font-weight: 800;">\
                            Cambia la tua password\
                        </h3>\
                        <span style="color: var(--optionalColor); font-size: var(--fontSize);">\
                            inserisci la nuova password\
                        </span><br>\
                        <div class="form-group pt-3" align="center">\
                            <input type="text" class="form-control" id="newPassword" placeholder="inserisci la nuova password">\
                        </div>\
                       </div>',
                showCancelButton: true,
                confirmButtonColor: 'var(--mainColor)',
                cancelButtonColor: '#dadada',
                confirmButtonText: 'Aggiorna password',
                cancelButtonText: 'Annulla',
                width: '400px',
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isConfirmed) {
                    Swal.showLoading();
                    let newPassword=(document.getElementById('newPassword')  as HTMLInputElement).value;
                    if(newPassword) {
                        this.crudService.api_modificaPassword(this.idCorsista, newPassword).subscribe(
                            productData => {
                                if(productData==1) {
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Fatto!',
                                        text: 'password cambiata con successo. Non dimentare la tua nuova password.',
                                        showCancelButton: false,
                                        confirmButtonColor: '#6993FF',
                                        confirmButtonText: 'Grazie',
                                        width: '400px',
                                        allowOutsideClick: false,
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                        }
                                      })
                                }
                                else {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Errore!',
                                        text: 'non riesco a cambiare la password, contatta l\'assistenza.',
                                        showCancelButton: false,
                                        confirmButtonColor: '#6993FF',
                                        confirmButtonText: 'Riprova',
                                        width: '400px',
                                        allowOutsideClick: false,
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                        }
                                      })
                                }
                            },(err)=>{
                                console.log(err);
                                }, ()=>{
                            }
                        );
                    }
                } else if(result.isDismissed) {
                }
              })
        }
    })
  }

}
